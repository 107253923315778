<script>
  import { mapActions } from "vuex";
  import "@vueform/multiselect/themes/default.css";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import Lottie from "@/components/widgets/lottie.vue";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'TableDeliveries',
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk,};
    },
    page: {
      title: "Lista cantieri",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Lista cantieri",
        items: [{
            text: "Cantieri",
            href: "/projects/list-view",
          },
          {
            text: "Lista",
            active: true,
          },
        ],
        
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
        },
      
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        projectsList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        startUnixOnloadPage:moment().unix(),
        endUnixOnloadPage:moment().add(1,'days').unix(),
        form:{
          start:moment().format('YYYY-MM-DD'),
          end:moment().format('YYYY-MM-DD'),
          dbgruppo:'SP',
          status:'pending',
          select:'',
        },
        n_results:0,
        minDate: moment().format('YYYY-MM-DD HH:MM'),
        maxDate: moment().format('YYYY')+ '-12-31 HH:MM',
        minDateEnd:moment().format('YYYY-MM-DD HH:MM'),
        maxDateEnd: moment().format('YYYY') + '-12-31 HH:MM',
      };
    },
    components: {
      //lottie: Lottie,
  
     
    },

    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    mounted() {
      this.getList()
    },

    methods: {
      convertDate(date){
        if (date) {
          moment.locale('it')
          return moment.unix(date).format('DD/MM/YYYY')
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.form.start
          this.form.end = this.form.start
        }
        if (type=='end'){
          this.minDateEnd = this.form.start
        }
      },
      ...mapActions('layout', ['changeLayoutType', ]),
    
        axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })

                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }            
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}worksites/deliveries/per-page/tab/all`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
              start: this.startUnixOnloadPage,
              end: this.endUnixOnloadPage,
              status:this.form.status,
              dbgruppo:this.form.dbgruppo,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.projectsList = response.data.results.data;
          this.paginated = response.data.results
          this.n_results = response.data.n_results
        })
      },
      SearchData(){
        if (this.form.start!='' && this.form.end!=''){
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}worksites/deliveries/per-page/tab/all`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.select,
                search: this.search,
                start:moment(this.form.start).unix(),
                end:moment(this.form.end).unix(),
                status:this.form.status,
                dbgruppo:this.form.dbgruppo,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.projectsList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
            })
        }
      },
      filterOptions(val){
        switch(val){
          case "azienda":
            this.getSettingsOptions("tax-benefit-business")
          break;
          case "privato":
            this.getSettingsOptions("tax-benefit-private")
          break;
          case "pubblico":
            this.getSettingsOptions("tax-benefit-pa")
          break;
          default:
          this.optionsFiscalBenefit = []
        }   
      },
      getSettingsOptions(tag){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/tag/${tag}`, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          if (response.data) {
            this.optionsFiscalBenefit = []
            for (let item of response.data) {
              this.optionsFiscalBenefit.push({
                value:item.value,
                label: item.name
              })
            }
            
          }
        })
      },
      editdata(data) {
        this.$router.push('/warehouse/projects/detail/delivery/'+data)
      },
      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('createModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
      },
      initItem(){

        this.submitted = true;
        
        this.v$.$touch();

        this.item.name = 'Nuovo progetto avviato'
        this.item.dateString = moment().format('YYYY-MM-DD')
        this.item.dateTmp = moment().unix()
        this.item.year = moment().format('YYYY')
        this.item.start = moment().valueOf()
        this.item.start_tmp = moment().unix()
        this.item.createdBy = this.datastore.auth.currentUser
        this.item.consultantID  = this.datastore.auth.currentUser.id 
        this.item.consultantDetails = {
          userID: this.datastore.auth.currentUser.id,
          uid: this.datastore.auth.currentUser.uid,
          firstName: this.datastore.auth.currentUser.firstName,
          lastName: this.datastore.auth.currentUser.lastName,
        }
        if (this.item.source!=''&&this.item.customerCategory!=''&&this.item.fiscalBenefit!='')
        {
          axios.post(`${this.UrlServer}projects/register`, this.item, {headers: { authorization:this.tk}} )
            .then((response)=>{
              let self = this
              document.getElementById("closemodal").click();
              Swal.fire({  
                title: this.$t("t-msg-project-init") , 
                text: this.$t("t-msg-project-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
                }).then(()=>{
                  //self.$router.push('/worksite/detail/'+response.data)
                  if (self.$route.name=='projects-list-view') {
                    self.$router.push('/projects/detail/'+response.data)
                  }
                  if (self.$route.name=='my-projects-list-view') {
                    self.$router.push('/my-projects/detail/'+response.data)
                  } 
                });
              
            })
        }
      },
      deletedata(id) {
          Swal.fire(
            {
              title: this.$t("t-delete-confirm-setting-title"),
              text: this.$t("t-delete-confirm-setting-text"),
              icon: this.$t("t-delete-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
            }
          ).then((result) => {
            if (result.value) {
              try {
                this.axiosInterceptor()
                axios.delete(`${this.UrlServer}projects/${id}`, {headers: { authorization:this.tk}})
                  .then((response) => {
                    if (response.data) {
                      this.getList()
                      Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                    }

                  })
              }catch(error){
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

            }
          });
      },  
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(customerList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return customerList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
        if (str){
          return str.replaceAll(car,' ')
        } else {
          return ''
        } 
      }
    },

  };
</script>

<template>
<!-- Modulo tabella elenco progetti/cantieri -->    
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Tutte le consegne </h5>
              <div class="flex-shrink-0">

              </div>
            </div>
          </div>
      
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <div class="mb-50">
              <div class="row rowSearchFormBGce g-4 mb-50">

                  <div class="col-lg-2 col-md-12">
                    <div class="">
                        <select class="form-select" v-model="form.dbgruppo">
                            <option value="nd">Seleziona Magazzino Azienda</option>
                            <option value="SP">Sunservice</option>
                            <option value="ES">S.Energy Solutions</option>
                        </select>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-from')"   class="form-control" v-model="form.start" :min="minDate" :max="maxDate" @change="updateDate('start')">
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-to')"   class="form-control" v-model="form.end" :min="minDateEnd" :max="maxDateEnd"  @change="updateDate('end')" >
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <select class="form-select" v-model="form.status">

                       <option value="pending">In attesa di lavorazione</option>
                       <option value="processing">In lavorazione</option>
                       <option value="done">Inviati ad ESolver</option>

                    </select>

                  </div>
                  <div class="col-lg-1 col-md-12">
                    <button type="button" class="btn btn-primary w-100" @click="SearchData">
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      {{ $t('t-filters') }}
                    </button>
                  </div>
                
              </div>
            </div>
            <div class="p-2">
            </div>
           <div class="table-card mt-20">
            <div class="row">
              <div class="col-lg-4 col-md-12 pl-20">
                <h5 class="card-title mb-0 flex-grow-1">
                  Risultati: <span v-if="n_results>0" class="text-green bold">{{ n_results }}</span>  <span v-if="n_results==0" class="text-red bold">{{ n_results }}</span>
                </h5>
              </div>
              <div class="col-lg-8 col-md-12">
              </div>
            </div>
                <div class="row thRow mt-50">
                    <div class="col-lg-1 col-md-12">Num.</div>
                    <div class="col-lg-2 col-md-12">
                        Cliente
                    </div>
                    <div class="col-lg-2 col-md-12">
                        Articoli
                    </div>
                    <div class="col-lg-3 col-md-12">
                      Localizzazione consegna
                    </div>
                    <div class="col-lg-1 col-md-12">
                     Inizio
                    </div>
                    <div class="col-lg-1 col-md-12">
                      Fine
                    </div>
                   
                    <div class="col-lg-1 col-md-12">
                      Stato lavorazione
                    </div>

                    <div class="col-lg-1 col-md-12 text-left">
                        Visualizza scheda
                    </div>
                </div>
              
              <div class="row list tRow form-check-all d-flex" v-for="(item, index) of projectsList" :key="index">

                <div class="col-lg-1 col-md-12">{{ item.wsCode }}</div>
                <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" v-if="item.project">
                          <strong v-if="item.project[0].customerDetails.ragSoc!=''">{{ item.project[0].customerDetails.ragSoc }}</strong>
                          <template v-if="item.project[0].customerDetails.nome!=''">
                            <br> <strong v-if="item.project[0].customerDetails.nome!=''">{{ item.project[0].customerDetails.nome }}</strong> <strong v-if="item.project[0].customerDetails.cognome!=''">{{ item.project[0].customerDetails.cognome }}</strong>
                          </template>                         
                        </div>
                        <div class="list-inline hstack gap-2 mb-0" v-else>
                          ND
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                      <template v-if="item.carts.length>0">
                        <div v-for="i of item.carts" :key="i._id">           
                          <a href="javascript:void(0)" v-if="i.doc_type=='row'"> {{ i.detail_item.Nome }} </a><br>
                        </div>
                      </template>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                      <template v-if="item.worksite">
                        <template v-if="item.worksite.length>0">
                          {{ item.worksite[0].address }}
                          <br>
                          <strong>{{ item.worksite[0].city }} {{ item.worksite[0].zipCode }}</strong> 
                        </template>
                      </template>
                    </div>
                    <div class="col-lg-1 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" >
                          <template v-if="item.start_tmp">
                              {{ convertDate(item.start_tmp) }}
                          </template>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" >
                          <template v-if="item.end_tmp">
                              {{ convertDate(item.end_tmp) }}
                          </template>
                        </div>
                    </div>

                    <div class="col-lg-1 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                          
                                <template v-if="item.order_sd_status=='init' || item.order_sd_status==''">
                                  Non Assegnato
                                </template>
                                <template v-else-if="item.order_sd_status=='processing'">
                                  <div class="avatar-sm flex-shrink-0">
                                  <span class="avatar-title bg-info rounded-circle "  title="In lavorazione">
                                    <i class="mdi mdi-timer-sand"></i>
                                  </span>
                                  </div>                              
                                </template>
                                <template v-else-if="item.order_sd_status=='pending'">
                                  <div class="avatar-sm flex-shrink-0">
                                  <span class="avatar-title bg-warning rounded-circle "  title="In attesa">
                                    <i class="bx bx-time-five"></i>
                                  </span>
                                  </div>
                                </template>   
                                <template v-else-if="item.order_sd_status=='problem'">
                                  <div class="avatar-sm flex-shrink-0">
                                    <span class="avatar-title bg-danger rounded-circle" title="Problematica">
                                      <i class="bx bx-alarm-exclamation"></i>
                                  </span>
                                  </div>
                                </template>                         
                                <template v-else-if="item.order_sd_status=='done'">
                                  <div class="avatar-sm flex-shrink-0">
                                    <span class="avatar-title bg-success rounded-circle"  title="Completa">
                                      <i class="ri-checkbox-circle-line"></i>
                                  </span>
                                  </div>
                                </template>
                                <template v-else>
                                  <div class="avatar-sm flex-shrink-0">
                                    <span class="avatar-title bg-info rounded-circle "  title="In lavorazione">
                                    <i class="mdi mdi-timer-sand"></i>
                                  </span>
                                  </div>
                                </template>
                        </div>
                    </div>

                    <div class="col-lg-1 col-md-12 text-left">
                        <ul class="list-inline ">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                            <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="editdata(item._id)">
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>                                                
           <!-- END TABLE -->
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->                                   
</template>